import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
        <path d="M6385 7646 c-35 -6 -92 -28 -135 -52 -41 -23 -106 -59 -145 -79 -38
-21 -158 -85 -265 -143 -107 -57 -246 -132 -308 -165 -63 -34 -118 -69 -123
-79 -5 -10 -13 -107 -16 -216 l-6 -198 49 -26 c27 -14 157 -91 289 -171 l240
-145 -3 -119 c-8 -377 -32 -1234 -36 -1323 -3 -58 -2 -111 3 -118 4 -8 57 -24
117 -37 60 -13 206 -48 324 -77 l215 -53 198 -3 c141 -2 203 1 213 9 12 10 14
197 14 1232 l0 1220 -262 265 c-145 145 -269 271 -276 278 -7 8 -16 13 -20 13
-4 -1 -34 -7 -67 -13z m54 -566 c-4 -289 -13 -799 -19 -1135 -6 -335 -10 -717
-10 -847 0 -130 -3 -243 -6 -252 -8 -22 -92 -22 -100 -1 -3 8 -1 261 5 562 6
302 16 814 21 1138 5 325 12 683 16 797 l6 208 -48 -6 c-46 -6 -122 -45 -569
-285 -135 -73 -284 -149 -292 -149 -13 0 28 25 122 75 50 26 142 76 205 110
63 34 165 88 225 120 109 58 285 153 321 174 23 14 117 30 125 22 3 -3 3 -242
-2 -531z"/>
<path d="M5125 7521 c-78 -20 -205 -97 -205 -124 0 -8 11 -24 25 -37 l24 -23
32 27 c129 109 329 111 463 4 l38 -31 30 30 30 30 -44 36 c-80 66 -149 90
-263 93 -55 2 -113 0 -130 -5z"/>
<path d="M5139 7377 c-43 -16 -102 -52 -118 -70 -8 -10 -4 -20 18 -41 l28 -27
37 25 c82 55 180 55 261 0 l38 -25 28 27 c28 26 28 28 11 47 -9 10 -39 31 -67
45 -61 31 -179 40 -236 19z"/>
<path d="M4288 7286 c-65 -11 -122 -25 -126 -31 -5 -5 -29 -59 -52 -120 -23
-60 -72 -184 -107 -275 -36 -91 -100 -255 -143 -365 -75 -194 -343 -882 -442
-1135 -186 -476 -205 -527 -192 -539 5 -4 65 -15 134 -25 166 -22 496 -71 645
-96 91 -16 156 -20 269 -18 l149 3 91 247 c50 136 94 248 97 248 3 0 23 -71
44 -157 57 -237 52 -223 81 -223 14 0 112 -16 217 -36 106 -19 228 -42 272
-50 44 -8 114 -22 155 -31 55 -12 131 -17 280 -17 187 -1 205 1 208 16 3 17
-45 175 -264 873 -52 165 -115 365 -140 445 -25 80 -51 163 -59 185 -7 22 -30
94 -50 160 -20 66 -47 152 -60 190 -12 39 -42 132 -66 208 -23 75 -47 137 -52
137 -6 0 -78 41 -161 92 -83 50 -236 143 -341 206 -104 63 -191 118 -193 123
-5 13 -63 9 -194 -15z m173 -37 c4 -13 22 -76 39 -139 17 -63 36 -128 41 -145
5 -16 34 -120 65 -230 30 -110 76 -272 100 -360 25 -88 61 -216 80 -285 39
-139 97 -342 238 -840 52 -184 97 -352 101 -372 l7 -38 -50 0 -50 0 -42 153
c-104 377 -145 524 -190 687 -27 96 -58 209 -69 250 -11 41 -47 174 -81 295
-34 121 -81 292 -105 380 -52 192 -129 468 -152 549 -9 32 -22 60 -29 63 -6 2
-53 -3 -104 -12 -51 -9 -94 -15 -95 -13 -1 2 1 12 5 24 7 18 24 23 127 38 65
10 123 19 128 21 18 6 29 -1 36 -26z m-247 -611 c3 -13 6 -32 6 -43 0 -11 -63
-188 -140 -394 -77 -206 -138 -376 -136 -378 3 -3 105 2 228 10 127 9 227 12
232 7 20 -20 -4 -39 -56 -44 -144 -15 -469 -28 -477 -20 -7 7 99 299 305 842
18 47 29 53 38 20z m-434 -1222 c0 -8 -47 -139 -104 -293 -97 -261 -105 -278
-130 -281 -16 -2 -26 2 -26 10 0 22 203 562 214 570 18 13 46 9 46 -6z"/>
<path d="M5164 7235 c-52 -23 -58 -38 -25 -69 24 -23 32 -26 49 -17 27 14 77
14 101 -1 16 -9 23 -7 46 16 23 23 25 29 13 43 -33 40 -124 54 -184 28z"/>
<path d="M5197 7102 c-22 -24 -21 -55 1 -75 37 -34 92 -11 92 38 0 50 -60 73
-93 37z"/>
<path d="M5110 4489 c-47 -4 -131 -8 -188 -10 -159 -4 -280 -35 -339 -87 -19
-15 -44 -20 -140 -25 -94 -5 -137 -12 -207 -36 -49 -16 -93 -35 -97 -43 -5 -7
-7 -51 -4 -98 l4 -85 -16 53 -16 52 -67 0 c-78 0 -118 -19 -88 -42 10 -7 22
-33 27 -58 5 -25 16 -76 25 -115 23 -103 21 -122 -14 -140 -16 -8 -37 -15 -47
-15 -17 1 -17 1 0 11 9 5 17 18 17 27 0 14 2 15 10 2 8 -12 10 -12 16 4 5 14
9 15 15 4 6 -9 9 -7 9 9 0 18 -5 21 -27 20 -22 -2 -29 3 -31 21 -3 18 1 22 22
22 14 0 26 5 26 11 0 7 -11 9 -26 6 -20 -3 -27 1 -35 20 -12 33 -12 33 12 33
11 0 17 5 14 10 -3 6 -14 10 -24 10 -15 0 -20 11 -26 48 -9 61 -21 82 -46 82
-31 0 -55 -17 -79 -55 -12 -19 -28 -35 -36 -35 -8 0 -14 -11 -15 -27 l-1 -28
-11 28 c-8 20 -18 27 -37 27 -36 0 -160 -83 -190 -128 -23 -34 -25 -44 -22
-142 1 -58 6 -127 10 -155 7 -47 9 -50 37 -48 38 3 57 23 62 70 4 39 45 86 86
99 31 10 33 -7 4 -32 -23 -20 -25 -25 -13 -30 22 -8 20 -37 -4 -59 -22 -20
-25 -46 -10 -75 8 -16 20 -18 71 -17 51 2 64 6 80 26 10 13 19 38 19 56 0 18
6 37 13 43 7 6 49 12 92 14 79 3 80 3 95 35 12 27 21 33 48 33 18 0 48 6 67
14 l34 15 3 77 3 77 70 22 c185 60 225 103 225 243 0 24 -7 56 -15 72 -18 35
-5 40 23 9 25 -26 33 -66 22 -110 -20 -79 -33 -115 -48 -133 -22 -23 -82 -54
-144 -72 -46 -14 -48 -16 -48 -51 l0 -36 83 5 c84 5 206 46 246 82 10 9 34 16
53 16 74 0 94 10 114 53 21 45 44 60 44 29 0 -26 18 -32 95 -32 70 0 95 14 95
56 0 19 5 24 25 24 21 0 25 -5 25 -31 l0 -32 157 8 c87 4 165 11 174 16 20 10
37 51 42 99 l4 35 1 -38 c1 -23 8 -40 17 -43 8 -4 12 -10 9 -16 -3 -5 -3 -20
0 -33 5 -21 13 -23 83 -29 43 -3 108 -8 145 -12 63 -6 68 -5 91 20 21 22 33
26 83 26 l59 0 49 88 c58 102 93 152 107 152 6 0 2 -15 -9 -32 -11 -18 -31
-53 -45 -78 -13 -25 -36 -65 -51 -90 -37 -65 -35 -74 27 -90 28 -7 46 -15 40
-17 -7 -3 -29 1 -48 7 -41 13 -55 8 -48 -20 4 -14 22 -25 62 -36 31 -9 76 -23
101 -31 25 -8 74 -17 110 -20 45 -4 72 -11 86 -25 39 -35 119 -58 200 -58 64
0 75 2 65 14 -8 10 -8 16 1 24 17 13 58 -3 58 -23 0 -30 45 -77 103 -106 31
-16 57 -31 57 -33 0 -11 -81 20 -133 50 -66 38 -67 39 -67 21 0 -23 52 -64
120 -96 56 -27 76 -31 148 -31 79 0 85 2 113 29 21 22 29 39 29 65 0 33 2 36
28 36 49 0 65 29 60 109 -4 84 -15 98 -111 146 -56 28 -81 35 -128 35 l-59 0
0 40 c0 52 -20 62 -113 58 -56 -2 -72 -6 -72 -18 0 -11 8 -14 28 -12 29 4 29
3 -24 -65 -76 -99 -90 -113 -116 -113 -13 0 -23 3 -23 8 0 4 27 42 60 86 56
75 59 81 56 128 -3 33 -10 54 -22 63 -24 18 -145 20 -162 3 -17 -17 -15 -26 9
-30 l21 -3 -22 -25 c-39 -44 -99 -137 -105 -163 -11 -42 20 -85 78 -113 29
-13 43 -24 32 -24 -29 0 -117 43 -139 68 -17 20 -17 22 -2 28 21 8 21 33 -1
49 -15 11 -15 15 -4 29 14 16 13 17 -56 41 -9 3 -4 20 17 55 25 42 30 62 30
115 0 62 -1 65 -27 72 -16 4 -55 7 -88 7 -46 1 -64 6 -82 22 -28 26 -169 54
-271 54 -54 0 -71 3 -76 16 -5 13 -27 15 -161 13 -85 -1 -158 0 -161 4 -9 9
-151 7 -264 -4z m-250 -180 c0 -142 -8 -179 -37 -179 -11 0 -13 4 -6 13 17 21
22 59 24 185 0 67 5 122 10 122 5 0 9 -63 9 -141z m380 111 c-6 -11 -25 -22
-48 -25 -20 -3 -45 -8 -55 -10 -11 -2 -17 1 -14 8 2 7 25 15 52 19 26 4 53 12
58 17 16 16 19 13 7 -9z m419 -37 c-11 -27 -29 -66 -39 -88 -51 -107 -61 -149
-37 -158 7 -3 5 -6 -6 -6 -26 -1 -31 24 -14 62 9 17 25 56 36 85 24 61 69 152
76 152 2 0 -5 -21 -16 -47z m-198 17 c5 0 9 -3 9 -8 0 -4 -19 -6 -42 -3 -34 3
-39 5 -23 12 11 4 26 6 33 3 8 -2 18 -4 23 -4z m-446 -10 c4 -6 -11 -10 -37
-9 -36 1 -39 2 -18 9 36 11 48 11 55 0z m-304 -37 c-22 -11 -34 -27 -43 -54
-11 -38 -38 -54 -38 -23 0 37 32 84 64 93 43 11 54 2 17 -16z m1140 -3 c25
-14 25 -62 -1 -85 -23 -20 -24 -17 -10 19 12 32 -9 56 -50 56 -16 0 -32 5 -35
10 -8 13 71 13 96 0z m-740 -55 c-6 -35 -9 -41 -10 -20 -1 30 9 78 15 73 2 -2
-1 -26 -5 -53z m-794 -42 l-39 -16 7 -80 c5 -54 4 -78 -3 -73 -6 3 -13 2 -17
-4 -16 -25 -25 -5 -25 55 0 53 3 63 15 59 8 -4 15 -2 15 4 0 5 -9 12 -20 15
-11 3 -20 9 -20 14 0 16 55 42 90 42 l35 0 -38 -16z m780 -35 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m276 5 c-18 -2 -48 -2 -65 0 -18 2 -4 4
32 4 36 0 50 -2 33 -4z m-287 -58 c-3 -8 -11 -15 -18 -14 -10 0 -10 2 0 6 6 2
12 14 13 26 0 12 3 16 6 9 3 -7 2 -19 -1 -27z m-369 18 c-4 -3 -24 -9 -44 -12
-32 -5 -41 -13 -56 -44 -17 -37 -50 -58 -85 -56 -10 1 -6 4 8 8 16 4 32 20 44
47 15 32 27 43 55 51 42 12 87 16 78 6z m1102 -9 c9 -8 -105 -2 -124 6 -13 6
6 7 50 3 39 -3 72 -7 74 -9z m956 -114 c3 -5 0 -10 -7 -10 -8 0 -20 -6 -26
-12 -7 -7 -20 -13 -29 -13 -9 0 -17 -6 -19 -13 -1 -7 0 -8 3 -2 11 24 23 7 20
-29 -2 -22 0 -38 5 -35 4 3 8 1 8 -5 0 -5 25 -35 56 -66 31 -31 53 -59 50 -62
-8 -9 -77 28 -98 52 -10 11 -16 28 -14 37 2 10 -4 18 -18 22 -12 3 -33 15 -46
26 l-24 20 30 43 c27 38 42 47 96 56 4 0 10 -3 13 -9z m-655 -40 c37 -13 44
-18 20 -14 -43 8 -110 32 -90 33 8 0 40 -8 70 -19z m-2322 -3 c17 4 22 1 22
-15 0 -11 3 -28 7 -38 5 -13 3 -16 -10 -11 -11 4 -17 1 -17 -9 0 -11 6 -13 20
-9 15 5 20 2 20 -13 0 -15 -5 -19 -20 -15 -11 3 -20 0 -20 -6 0 -6 11 -11 25
-11 16 0 25 -6 25 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -4 -10 -10 0 -5 7 -7 15
-4 19 8 20 -10 1 -25 -18 -15 -48 -3 -44 18 1 9 -5 30 -14 48 -10 21 -12 35
-6 41 12 12 1 35 -12 27 -11 -7 -16 2 -25 43 -6 31 -5 33 12 23 11 -5 29 -7
41 -4z m3105 -62 c33 -23 39 -31 36 -51 0 -3 8 -12 18 -20 15 -11 16 -13 2
-14 -16 0 -119 89 -119 103 0 14 26 7 63 -18z m-3258 -26 c7 -38 4 -44 -21
-65 -42 -34 -54 -31 -54 16 0 31 6 47 26 65 15 14 30 25 35 25 4 0 10 -19 14
-41z m2985 -14 c-9 -11 -17 -12 -34 -3 -33 17 -32 18 9 18 32 0 36 -2 25 -15z
m-2750 -98 c0 -21 -31 -44 -70 -52 l-35 -7 19 21 c11 12 26 19 33 17 7 -3 19
3 25 14 15 23 28 26 28 7z"/>
<path d="M3765 3990 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M3920 4160 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3940 4105 c-7 -9 -10 -19 -6 -22 4 -4 11 -1 15 6 7 11 10 11 14 0 4
-10 6 -9 6 3 1 24 -14 31 -29 13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
